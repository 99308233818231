/**
 * @description Transform a route to send to the ordering app.
 * Required because slashes are encoded as %2F in the URL.
 * @param route {string} The route to transform
 * @returns {string} The parsed route
 */
export function transformEmbeddedRoute(route: string): string {
  return route.replaceAll('/', '%2F')
}

/**
 * @description Replace \n with <br />
 * @param text {string | { [x: string]: string | undefined }} The Short Text ("Symbol") from Contentful
 * @returns {string} The string with break tags
 */
export function withLinebreaks(text: string | { [x: string]: string | undefined }): string {
  return (text as string).replaceAll('\\n', '<br />')
}
